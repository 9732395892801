<nav class="sidebar" [ngClass]="{ 'sidebar--collapsed': collapsed }">
  <div class="sidebar__item" *ngFor="let menu of menuItems">
    <a
      class="sidebar__item__link"
      [routerLink]="[menu.path]"
      routerLinkActive="active"
    >
      <md-icon
        class="sidebar__item__link__icon"
        [size]="'s'"
        [name]="menu.icon"
      ></md-icon>
      <span class="sidebar__item__link__label">
        {{ menu.label | translate }}
      </span>
    </a>
  </div>
</nav>
