import {
  AppConfigResponse,
  TeamManagerConfigResponse,
} from '../../interfaces/auth';

export class AppConfigModel {
  public messageCentral: boolean;
  public survey: boolean;
  public bannerStats: boolean;
  public bannerVoucherAwardable: boolean;
  public quizPoll: boolean;
  public survey_lead: boolean;
  public survey_crowdmanager: boolean;
  public loyalty: boolean;
  public streamManagement: boolean;
  public leagueManagement: boolean;
  public playerManagement: boolean;
  public audienceManagement: boolean;
  public spotManagement: boolean;
  public libraryFoldersAndImages: boolean;
  public settingsShop: boolean;
  public settingsTicket: boolean;
  public usersStatistics: boolean;
  public analyticsStatistics: boolean;

  public constructor(a: AppConfigResponse) {
    const t: TeamManagerConfigResponse = JSON.parse(a.TeamManagerConfig);
    this.messageCentral = t.message_central?.enabled || false;
    this.survey = t.survey?.enabled || false;
    this.bannerStats = t.banner.stats?.enabled || false;
    this.bannerVoucherAwardable = t.banner.voucher_awardable || false;
    this.quizPoll = t.quiz_poll?.enabled || false;
    this.survey_lead = t.survey?.lead_module || false;
    this.survey_crowdmanager = t.survey?.crowdmanager_module || false;
    this.loyalty = t.loyalty?.enabled || false;
    this.streamManagement = t.stream_management?.enabled || false;
    this.leagueManagement = t.league_management?.enabled || false;
    this.playerManagement = t.player_management?.enabled || false;
    this.audienceManagement = t.audience_management?.enabled || false;
    this.spotManagement = t.spot_management?.enabled || false;
    this.libraryFoldersAndImages = t.library?.foldersAndImages || false;
    this.settingsShop = t.settings_page.shop?.enabled || false;
    this.settingsTicket = t.settings_page.ticket?.enabled || false;
    this.usersStatistics = t.users_statistics?.enabled || false;
    this.analyticsStatistics = t.analytics_statistics?.enabled || false;
  }
}
