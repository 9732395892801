import { AuthGateway } from './../gateway/auth.gateway';
import { Injectable } from '@angular/core';
import { AuthPayload } from 'src/app/shared/interfaces/auth';
import { Store } from '@ngrx/store';
import { authActions } from '../+state/auth.action';
import { authSelectors } from '../+state/auth.selectors';
import { MenuService } from '../../../../../src/app/core/services/menu/menu.service';
import { BehaviorSubject, Observable, combineLatest, map, of } from 'rxjs';
import { Menu } from '../../../../../src/app/shared/interfaces/ui/menu';
import { ForgotPasswordPayload } from 'src/app/shared/interfaces/auth/forgot-password-payload.interface';
import { AuthModel } from 'src/app/shared/models/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  public readonly status$ = this._store.select(authSelectors.status);
  public readonly refreshing$ = new BehaviorSubject(false);

  public readonly isAuthenticated$ = this._store.select(
    authSelectors.isAuthenticated
  );

  public readonly isGlobalAdmin$ = this._store.select(
    authSelectors.isGlobalAdmin
  );

  public readonly appConfig$ = this._store.select(authSelectors.appConfig);

  public readonly roleClaims$ = this._store.select(authSelectors.roleClaims);

  public constructor(
    private readonly _store: Store,
    private readonly _menuService: MenuService,
    private _authGateway: AuthGateway
  ) {}

  /**
   *
   * Dispatches an action - authActions.init()
   */
  public initAuth(): void {
    this._store.dispatch(authActions.init());
  }

  /**
   *
   * Dispatches an action - authActions.login() with payload of type AuthPayload
   */
  public login(payload: AuthPayload): void {
    this._store.dispatch(authActions.login({ authPayload: payload }));
  }

  /**
   *
   * Dispatches an action - authActions.refreshToken() with payload of type refreshToken
   */
  public refreshToken(payload: string): Observable<AuthModel> {
    return this._authGateway.refreshToken(payload);
  }

  /**
   *
   * Dispatches an action - authActions.logout()
   */
  public logout(): void {
    this._store.dispatch(authActions.logout());
  }

  /**
   * Gets the menu definition of sidebar after combining appConfig, roleClaims and GlobalAdmin permissions.
   *
   * @returns
   * Observable of type array of type Menu
   */
  public getMenuList(): Observable<Menu[]> {
    return combineLatest([
      this.appConfig$,
      this.roleClaims$,
      this.isGlobalAdmin$,
    ]).pipe(
      map(([config, claims, isGlobalAdmin]) => {
        return this._menuService.getMenuDefinition(
          config,
          claims,
          isGlobalAdmin
        );
      })
    );
  }

  public forgotPassword(payload: ForgotPasswordPayload): Observable<unknown> {
    return this._authGateway.forgotPassword(payload);
  }
}
