import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Output, EventEmitter } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconComponent } from '../../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthEffects } from '../../../../data-access/auth/+state/auth.effects';
import { MatDividerModule } from '@angular/material/divider';
@Component({
  selector: 'md-topbar',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    IconComponent,
    TranslateModule,
    MatDividerModule,
  ],
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Output() public toggleEvent = new EventEmitter<void>();
  @Output() public logoutEvent = new EventEmitter<void>();
  @Input() public orgName: string = '';
  public decodedToken: any;
  public constructor(private readonly _authEffects: AuthEffects) {}
  public ngOnInit(): void {
    this.decodedToken = this._authEffects._getDecodedToken();
  }
}
