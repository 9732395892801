import { Injectable, inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { TokenService } from '../services/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public constructor(
    private readonly _router: Router,
    private readonly _tokenService: TokenService
  ) {}

  public canActivate(): boolean {
    if (this._tokenService.hasAccessToken()) {
      return true;
    } else {
      this._router.navigate(['/auth/login']); // go to login if not authenticated
      return false;
    }
  }
}

export const isAuthGuard: CanActivateFn = (): boolean => {
  return inject(AuthGuard).canActivate();
};
