import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { AccessTokenResponse } from 'src/app/shared/interfaces/auth';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly accessTokenKey: string = 'access_token';
  private readonly refreshTokenKey: string = 'refresh_token';

  /**
   *
   * remove all tokens from local storage.
   *
   */
  public removeAllTokens(): void {
    localStorage.clear();
  }

  /**
   * Sets access token to local storage
   *
   * @param token {string}
   * Takes token of type string
   *
   */
  public setAccessToken(token: string): void {
    localStorage.setItem(this.accessTokenKey, token);
  }

  /**
   *
   * Gets access token from local storage
   *
   * @returns
   * string containing the token or null
   *
   */
  public getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  /**
   *
   * Checks if access token is present in local storage
   *
   * @returns
   * boolean indicating if token is present
   *
   */
  public hasAccessToken(): boolean {
    return this.getAccessToken() !== null;
  }

  /**
   *
   * sets refresh token to local storage
   *
   */
  public setRefreshToken(token: string): void {
    localStorage.setItem(this.refreshTokenKey, token);
  }

  /**
   *
   * gets refresh token from local storage
   *
   * @returns
   * string containing refresh token
   *
   */
  public getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  /**
   *
   * Checks if refresh token is present in local storage
   *
   * @returns
   * boolean indicating if refresh token is present in localstorage
   *
   */
  public hasRefreshToken(): boolean {
    return this.getRefreshToken() !== null;
  }

  /**
   *
   * returns decoded token
   *
   * @returns
   * string containing decoded access token
   *
   */
  public decodeToken(token: string): AccessTokenResponse {
    return jwtDecode(token);
  }
}
