import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RoleClaimEnum } from 'src/app/shared/dicts/auth';
import { AuthPayload } from 'src/app/shared/interfaces/auth';
import { AppConfigModel } from 'src/app/shared/models/auth';

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    // INIT ACTION
    ['Init']: emptyProps(),
    ['Init success']: props<{
      authenticated: boolean;
      globalAdmin: boolean;
      roleClaims: RoleClaimEnum[];
    }>(),

    // LOGIN ACTIONS
    ['Login']: props<{ authPayload: AuthPayload }>(),
    ['Login success']: props<{
      globalAdmin: boolean;
      roleClaims: RoleClaimEnum[];
    }>(),
    ['Login error']: emptyProps(),

    // REFRESH TOKEN
    ['Refresh token']: props<{ refreshToken: string }>(),
    ['Refresh token success']: props<{
      globalAdmin: boolean;
      roleClaims: RoleClaimEnum[];
    }>(),
    ['Refresh token error']: emptyProps(),

    // APP CONFIG ACTIONS
    ['Load app config']: emptyProps(),
    ['Load app config success']: props<{ appConfig: AppConfigModel }>(),
    ['Load app config error']: emptyProps(),

    // LOGOUT ACTIONS
    ['Logout']: emptyProps(),
    ['Logout success']: emptyProps(),
    ['Logout error']: emptyProps(),
  },
});
