import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IconType } from '../../types/icon.type';

@Component({
  selector: 'md-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() public name: IconType = null;
  @Input() public size: 's' | 'm' | 'l' = 's';
}
