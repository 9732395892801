<main class="main">
  <nav class="navbar">
    <div class="navbar__toggle">
      <button mat-icon-button (click)="toggleEvent.emit()">
        <md-icon [size]="'s'" [name]="'menu'"></md-icon>
      </button>
    </div>
    <div class="navbar__body">
      <div class="navbar__body__logo">
        {{ orgName }}
      </div>
      <div class="navbar__body__right-menu">
        <div class="navbar__body__right-menu__faq">
          <a target="_blank" href="https://manydigital.com/da/support-teammanager/">
            <button mat-stroked-button color="primary">
              <mat-icon>help_outline</mat-icon>FAQ
            </button>
          </a>
        </div>
        <div class="navbar__body__right-menu__bug-report">
          <a target="_blank" href="https://manydigital.com/da/support/">
            <button mat-stroked-button color="primary">
              <mat-icon>bug_report</mat-icon>{{ "BUG_REPORT" | translate }}
            </button>
          </a>
        </div>
        <mat-divider class="navbar__body__right-menu__divider" [vertical]="true"></mat-divider>
        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
          <div class="navbar__body__right-menu__user-initials">
            {{this.decodedToken.FirstName.charAt(0)}}{{this.decodedToken.LastName.charAt(0)}}
          </div>
          <div class="navbar__body__right-menu__user-name">{{this.decodedToken.FirstName}}
            {{this.decodedToken.LastName}}
          </div>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logoutEvent.emit()">Logout</button>
        </mat-menu>
      </div>
    </div>
  </nav>
  <ng-content></ng-content>
</main>