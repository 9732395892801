import { Directive, OnDestroy } from '@angular/core';
import { SubSink } from './subsink';

@Directive()
export class Unsubscriber implements OnDestroy {
  public subs = new SubSink();

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
