import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

const authState = createFeatureSelector<AuthState>('auth');

const status = createSelector(authState, (state: AuthState) => state.status);

const isAuthenticated = createSelector(
  authState,
  (state: AuthState) => state.authenticated
);

const isGlobalAdmin = createSelector(
  authState,
  (state: AuthState) => state.globalAdmin
);

const appConfig = createSelector(
  authState,
  (state: AuthState) => state.appConfig
);

const roleClaims = createSelector(
  authState,
  (state: AuthState) => state.roleClaims
);

export const authSelectors = {
  status,
  isAuthenticated,
  isGlobalAdmin,
  appConfig,
  roleClaims,
};
