import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../../../src/environments/environment';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    NgxMatDatetimePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(
      environment.firebaseConfig,
      environment.clubName
    ),
    ToastrModule.forRoot(),
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
})
export class CoreModule {
  public constructor(private readonly _translate: TranslateService) {
    const browserLang = this._translate.getBrowserLang()
      ? this._translate.getBrowserLang()
      : 'en';

    //Once translate.use('**') is selected it cannot be changed.
    if (environment.clubName == 'LimeLight Sports Club') {
      // This is a hack to give LimeLite their own translations
      this._translate.use('en-US-POSIX');

      if (browserLang?.toLowerCase().includes('da')) {
        this._translate.use('da-GL');
      }
    } else {
      //Standard Rules
      if (browserLang?.toLowerCase().includes('da')) {
        this._translate.addLangs(['da']);
        this._translate.setDefaultLang('da');
        this._translate.use('da');
      } else {
        //Default
        this._translate.addLangs(['en']);
        this._translate.setDefaultLang('en');
        this._translate.use('en');
      }
    }
  }
}
