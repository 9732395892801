import { Injectable } from '@angular/core';
import {
  ColorPalette,
  HSLInterface,
} from 'src/app/shared/interfaces/ui/color-palette';
import { ImageService } from 'src/app/shared/services/image.service';
import { environment } from 'src/environments/environment';

type PaletteSet = {
  relativePrimaryPalette: any[];
  relativeSecondaryPalette: any[];
  relativeTertiaryPalette: any[];
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  //Predefine array for saturation, lightness, hue
  readonly SATURATION_SHIFTS = [100, 100, 93, 84, 73, 73, 73, 79, 81, 83];
  readonly LIGHT_SHIFTS = [19, 25, 31, 41, 51, 66, 81, 94, 98, 102];
  readonly HUE_SHIFTS = [0, 0, 0, 0, 0, 0, 0, 0, 3, 5];
  // ---

  readonly DEFAULT_TERTIARY_COLOR = '#23c2a7'; // default MD color

  primaryHSL!: HSLInterface;
  secondaryHSL!: HSLInterface;
  tertiaryHSL!: HSLInterface;

  constructor(private readonly _imageService: ImageService) {}

  setup() {
    this.createPalette();
    this.setFavicon();
  }

  /**
   * Initiates generating a color palette using hex codes of primary and secondary colors provided.
   *
   * @param primary {string | null}
   * Takes param primary of type string
   *
   * @param secondary {string | null}
   * Takes param secondary of type string
   *
   */
  private createPalette(): void {
    const primary = environment.primaryColor;
    const secondary = environment.secondaryColor;
    const tertiary = environment.tertiaryColor || this.DEFAULT_TERTIARY_COLOR;

    if (primary && secondary) {
      this.primaryHSL = this.hexToHSL(primary);
      this.secondaryHSL = this.hexToHSL(secondary);
      this.tertiaryHSL = this.hexToHSL(tertiary);
      this.generateColorPallet(
        this.primaryHSL,
        this.secondaryHSL,
        this.tertiaryHSL
      );
    }
  }

  /**
   * generates a palette using hsl codes of primary and secondary colors provided
   *
   * @param primary {HSLInterface}
   * Takes param primary of type HSLInterface
   *
   * @param secondary {HSLInterface}
   * Takes param secondary of type HSLInterface
   *
   */
  private generateColorPallet(
    primary: HSLInterface,
    secondary: HSLInterface,
    tertiary: HSLInterface
  ): void {
    const midSaturation = this.SATURATION_SHIFTS[4];
    const midLight = this.LIGHT_SHIFTS[4];

    const {
      relativePrimaryPalette,
      relativeSecondaryPalette,
      relativeTertiaryPalette,
    }: PaletteSet = this.SATURATION_SHIFTS.reduce(
      (palettes, saturationShift, index) => {
        const hueShift = this.HUE_SHIFTS[index];
        const lightShift = this.LIGHT_SHIFTS[index];

        return {
          relativePrimaryPalette: [
            ...palettes.relativePrimaryPalette,
            this.getColorHSL(
              primary,
              saturationShift,
              lightShift,
              hueShift,
              midSaturation,
              midLight
            ),
          ],
          relativeSecondaryPalette: [
            ...palettes.relativeSecondaryPalette,
            this.getColorHSL(
              secondary,
              saturationShift,
              lightShift,
              hueShift,
              midSaturation,
              midLight
            ),
          ],
          relativeTertiaryPalette: [
            ...palettes.relativeTertiaryPalette,
            this.getColorHSL(
              tertiary,
              saturationShift,
              lightShift,
              hueShift,
              midSaturation,
              midLight
            ),
          ],
        };
      },
      {
        relativePrimaryPalette: [],
        relativeSecondaryPalette: [],
        relativeTertiaryPalette: [],
      } as PaletteSet
    );

    this.generateHexPalette(
      relativePrimaryPalette,
      relativeSecondaryPalette,
      relativeTertiaryPalette
    );
  }

  /**
   * sets theme after converting the HSL codes provided to Hex codes
   *
   * @param primaryPalette {HSLInterface[]}
   * Takes param primary of type HSLInterface[]
   *
   * @param secondaryPalette {HSLInterface[]}
   * Takes param secondary of type HSLInterface[]
   *
   */
  private generateHexPalette(
    primaryPalette: HSLInterface[],
    secondaryPalette: HSLInterface[],
    tertiaryPalette: HSLInterface[]
  ) {
    const themePalette = this.setCustomPalette(
      this.generateHexPaletteForColor(primaryPalette),
      this.generateHexPaletteForColor(secondaryPalette),
      this.generateHexPaletteForColor(tertiaryPalette)
    );

    this.setTheme(themePalette);
  }

  private generateHexPaletteForColor(colorPalette: HSLInterface[]) {
    return colorPalette.reduce((allColors: string[], color: HSLInterface) => {
      const hexColor = this.HSLToHex(color.h, color.s, color.l);

      return [...allColors, hexColor as string];
    }, [] as string[]);
  }

  private getColorHSL(
    color: HSLInterface,
    saturationShift: number,
    lightShift: number,
    hueShift: number,
    midSaturation: number,
    midLight: number
  ) {
    const calculatedSaturation = (saturationShift / midSaturation) * color.s;
    const calculatedLightness = (lightShift / midLight) * color.l;

    return {
      h: this.shiftInHueToDark(color.h, hueShift),
      s: calculatedSaturation > 100 ? 100 : calculatedSaturation,
      l: calculatedLightness > 100 ? 100 : calculatedLightness,
    };
  }

  /**
   * sets custom palettes using array of primary colors and secondary colors
   *
   * @param primaryColors {string[]}
   * Takes param primary of type string[]
   *
   * @param secondaryColors {string[]}
   * Takes param secondary of type string[]
   *
   */
  private setCustomPalette(
    primaryColors: string[],
    secondaryColors: string[],
    tertiaryColors: string[]
  ): ColorPalette {
    const steps = [
      '900',
      '800',
      '700',
      '600',
      '',
      '400',
      '300',
      '200',
      '100',
      '50',
    ];

    return steps.reduce((allClasses, step, i) => {
      const stepPostfix = step ? `-${step}` : '';

      return {
        ...allClasses,
        [`primary${stepPostfix}`]: primaryColors[i],
        [`secondary${stepPostfix}`]: secondaryColors[i],
        [`tertiary${stepPostfix}`]: tertiaryColors[i],
      };
    }, {});
  }

  private setFavicon() {
    const faviconEl = document.querySelector(
      'link[rel~="icon"]'
    ) as HTMLLinkElement;

    if (faviconEl) {
      faviconEl.href = this._imageService.APP_ICON_URL;
    }

    return;
  }

  /**
   * sets theme for the entire application
   */
  private setTheme(theme: any): void {
    Object.keys(theme).forEach((k) =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }

  /**
   * Converts Hex code to HSL
   *
   * @param H {string}
   * Takes param H of type string
   *
   * @returns
   * object containaing HSL code
   */
  private hexToHSL(H: string): HSLInterface {
    // Convert hex to RGB first
    let r = 0;
    let g = 0;
    let b = 0;

    if (H.length == 4) {
      r = Number('0x' + H[1] + H[1]);
      g = Number('0x' + H[2] + H[2]);
      b = Number('0x' + H[3] + H[3]);
    } else if (H.length == 7) {
      r = Number('0x' + H[1] + H[2]);
      g = Number('0x' + H[3] + H[4]);
      b = Number('0x' + H[5] + H[6]);
    }

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    const cMin = Math.min(r, g, b);
    const cMax = Math.max(r, g, b);
    const delta = cMax - cMin;
    let h = 0;
    let s = 0;
    let l = 0;

    if (delta == 0) {
      h = 0;
    } else if (cMax == r) {
      h = ((g - b) / delta) % 6;
    } else if (cMax == g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60); //FINAL H

    if (h < 0) {
      h += 360;
    }

    l = (cMax + cMin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    s = Math.round(s); //FINAL S
    l = Math.round(l); //FINAL l //return "hsl(" + h + "," + s + "%," + l + "%)";

    return { h, s, l };
  }

  /**
   * Converts HSL to Hex
   *
   * @param h {any}
   * Takes param h of type any
   *
   * @param s {any}
   * Takes param s of type any
   *
   * @param l {any}
   * Takes param s of type any
   *
   * @returns
   * object containaing Hex code
   */
  private HSLToHex(h: any, s: any, l: any): string | null {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    // Having obtained RGB, convert channels to hex

    let R = Math.round((r + m) * 255).toString(16);
    let G = Math.round((g + m) * 255).toString(16);
    let B = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (R.length == 1) {
      R = '0' + R;
    }

    if (G.length == 1) {
      G = '0' + G;
    }

    if (B.length == 1) {
      B = '0' + B;
    }

    return '#' + R + G + B;
  }

  /**
   * Shifts Hue to Dark
   *
   * @param hue {any}
   * Takes param hue of type any
   *
   * @param shift {any}
   * Takes param shift of type any
   *
   * @returns
   * string or null
   */
  private shiftInHueToDark(hue: any, shift: any): string | null {
    if (hue <= 0 && hue > 60) {
      hue = hue - shift > 0 ? hue - shift : 0;
    } else if (hue <= 120 && hue > 180) {
      hue = hue - shift > 120 ? hue - shift : 120;
    } else if (hue <= 240 && hue > 300) {
      hue = hue - shift > 240 ? hue - shift : 240;
    } else if (hue >= 60 && hue < 120) {
      hue = hue + shift < 120 ? hue + shift : 120;
    } else if (hue >= 180 && hue < 240) {
      hue = hue + shift < 240 ? hue + shift : 240;
    } else {
      hue = hue + shift < 360 ? hue + shift : 360;
    }

    return hue;
  }
}
