import { TokenType } from '../../types/token.type';
import { AuthResponse } from '../../interfaces/auth/auth-response.interface';

export class AuthModel {
  public accessToken: string;
  public tokenType: TokenType;
  public expiresIn: number;
  public refreshToken: string;

  public constructor(r: AuthResponse) {
    this.accessToken = r.access_token;
    this.refreshToken = r.refresh_token;
    this.tokenType = r.token_type;
    this.expiresIn = r.expires_in;
  }
}
