import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme/theme.service';
import { AuthFacade } from './data-access/auth/facade/auth.facade';
import { Menu } from './shared/interfaces/ui/menu';
import { environment } from '../../src/environments/environment';
import { map } from 'rxjs';
import { Unsubscriber } from './shared/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Unsubscriber implements OnInit {
  readonly hideNav$ = this._authFacade.isAuthenticated$.pipe(
    map((authenticated) => !authenticated)
  );

  constructor(
    private readonly _authFacade: AuthFacade,
    private readonly _themeService: ThemeService
  ) {
    super();
  }

  sidebarMenuData: Menu[] = [];
  orgName: string = environment.clubName;

  ngOnInit(): void {
    this._authFacade.initAuth();
    this._themeService.setup();

    // listen to menu list
    this.subs.sink = this._authFacade.getMenuList().subscribe((res) => {
      this.sidebarMenuData = res;
    });
  }

  logout(): void {
    this._authFacade.logout();
  }
}
