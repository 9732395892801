import { Injectable } from '@angular/core';
import { AuthGateway } from '../gateway/auth.gateway';
import { Router } from '@angular/router';
import { TokenService } from '../../../../../src/app/core/services/token/token.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from './auth.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AppConfigModel,
  AuthModel,
} from '../../../../../src/app/shared/models/auth';
import {
  ManageAudienceClaimEnum,
  RoleClaimEnum,
} from '../../../../../src/app/shared/dicts/auth';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  public constructor(
    private readonly _router: Router,
    private readonly _actions: Actions,
    private readonly _gateway: AuthGateway,
    private readonly _tokenService: TokenService
  ) {}

  public login$ = createEffect(() =>
    this._actions.pipe(
      ofType(authActions.login),
      switchMap(({ authPayload }) => {
        return this._gateway.login(authPayload).pipe(
          map((response: AuthModel) => {
            this._setTokens(response);
            this._router.navigate(['/dashboard']);
            return authActions.loginSuccess({
              roleClaims: this._getRoleClaims(),
              globalAdmin: this._checkGlobalAdmin(),
            });
          }),
          catchError(() => of(authActions.loginError()))
        );
      })
    )
  );

  public refreshToken$ = createEffect(() =>
    this._actions.pipe(
      ofType(authActions.refreshToken),
      switchMap(({ refreshToken }) => {
        return this._gateway.refreshToken(refreshToken).pipe(
          map((response: AuthModel) => {
            this._setTokens(response);
            return authActions.refreshTokenSuccess({
              roleClaims: this._getRoleClaims(),
              globalAdmin: this._checkGlobalAdmin(),
            });
          })
        );
      })
    )
  );

  public init$ = createEffect(() =>
    this._actions.pipe(
      ofType(authActions.init),
      switchMap(() => {
        const authenticated = this._tokenService.hasAccessToken();
        return [
          authActions.loadAppConfig(),
          authActions.initSuccess({
            authenticated,
            roleClaims: this._getRoleClaims(),
            globalAdmin: this._checkGlobalAdmin(),
          }),
        ];
      })
    )
  );

  public loadAppConfig$ = createEffect(() =>
    this._actions.pipe(
      ofType(authActions.loadAppConfig),
      switchMap(() => {
        return this._gateway.getAppConfig().pipe(
          map((appConfig: AppConfigModel) => {
            return authActions.loadAppConfigSuccess({ appConfig });
          })
        );
      })
    )
  );

  public logout$ = createEffect(() =>
    this._actions.pipe(
      ofType(authActions.logout),
      map(() => {
        this._tokenService.removeAllTokens();
        this._router.navigate(['/auth/login']);
        
        return authActions.logoutSuccess();
      })
    )
  );

  private _setTokens(response: AuthModel): void {
    this._tokenService.setAccessToken(response.accessToken);
    this._tokenService.setRefreshToken(response.refreshToken);
  }

  public _getDecodedToken(): any {
    if (this._tokenService.hasAccessToken()) {
      const accessToken = this._tokenService.getAccessToken();

      return this._tokenService.decodeToken(accessToken as string);
    }

    return null;
  }

  // TODO - extract consts
  private _getRoleClaims(): RoleClaimEnum[] {
    const token = this._getDecodedToken();

    if (token) {
      const key =
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

      return token[key] || [];
    }

    return [];
  }

  public _checkGlobalAdmin(): boolean {
    const token = this._getDecodedToken();

    if (
      token &&
      token['http://schemas.manydigital.com/ws/2022/claims/manageaudience']
    ) {
      const key =
        'http://schemas.manydigital.com/ws/2022/claims/manageaudience';
      const claims = token[key];

      return claims.includes(ManageAudienceClaimEnum.ALL);
    }

    return false;
  }
}
