export enum RoleClaimEnum {
  API_ACCESS = 'API_ACCESS',
  CLUB_MANAGER_ACCESS = 'CLUB_MANAGER_ACCESS',
  SPONSOR_MANAGEMENT_ACCESS = 'SPONSOR_MANAGEMENT_ACCESS',
  SPONSOR_DELETE_ACCESS = 'SPONSOR_DELETE_ACCESS	',
  USER_MANAGEMENT_ACCESS = 'USER_MANAGEMENT_ACCESS',
  DELETE_ACCESS = 'DELETE_ACCESS',
  AUDIENCE_MANAGEMENT_SECURITY_ACCESS = 'AUDIENCE_MANAGEMENT_SECURITY_ACCESS',
  AUDIENCE_MANAGEMENT_ACCESS = 'AUDIENCE_MANAGEMENT_ACCESS',
  EMAIL_SEGMENTATION_ACCESS = 'EMAIL_SEGMENTATION_ACCESS',
  LQ_ADMIN_ACCESS = 'LQ_ADMIN_ACCESS',
  LQ_APP_USER_ACCESS = 'LQ_APP_USER_ACCESS',
  LQ_PUBLISHER_ACCESS = 'LQ_PUBLISHER_ACCESS',
  LQ_TENENT_ADMIN_ACCESS = 'LQ_TENENT_ADMIN_ACCESS',
  AUDIENCE_DELETE_ACCESS = 'AUDIENCE_DELETE_ACCESS',
  SETTINGS_DELETE_ACCESS = 'SETTINGS_DELETE_ACCESS',
  SETTINGS_MANAGEMENT_ACCESS = 'SETTINGS_MANAGEMENT_ACCESS',
  TICKET_DELETE_ACCESS = 'TICKET_DELETE_ACCESS',
  TICKET_MANAGEMENT_ACCESS = 'TICKET_MANAGEMENT_ACCESS',
  STATS_DELETE_ACCESS = 'STATS_DELETE_ACCESS',
  PUSH_DELETE_ACCESS = 'PUSH_DELETE_ACCESS',
  PUSH_MANAGEMENT_ACCESS = 'PUSH_MANAGEMENT_ACCESS',
  POLL_DELETE_ACCESS = 'POLL_DELETE_ACCESS',
  SPOT_MANAGEMENT_ACCESS = 'SPOT_MANAGEMENT_ACCESS',
  SPOT_DELETE_ACCESS = 'SPOT_DELETE_ACCESS',
  STREAM_MANAGEMENT_ACCESS = 'STREAM_MANAGEMENT_ACCESS',
  STREAM_DELETE_ACCESS = 'STREAM_DELETE_ACCESS',
  POLL_MANAGEMENT_ACCESS = 'POLL_MANAGEMENT_ACCESS',
  QUIZ_DELETE_ACCESS = 'QUIZ_DELETE_ACCESS',
  QUIZ_MANAGEMENT_ACCESS = 'QUIZ_MANAGEMENT_ACCESS',
  RAFFLE_DELETE_ACCESS = 'RAFFLE_DELETE_ACCESS',
  RAFFLE_MANAGEMENT_ACCESS = 'RAFFLE_MANAGEMENT_ACCESS',
  VOUCHER_DELETE_ACCESS = 'VOUCHER_DELETE_ACCESS',
  VOUCHER_MANAGEMENT_ACCESS = 'VOUCHER_MANAGEMENT_ACCESS',
  CMS_DELETE_ACCESS = 'CMS_DELETE_ACCESS',
  STATS_MANAGEMENT_ACCESS = 'STATS_MANAGEMENT_ACCESS',
  CMS_MANAGEMENT_ACCESS = 'CMS_MANAGEMENT_ACCESS',
  LEAGUE_MANAGEMENT_ACCESS = 'LEAGUE_MANAGEMENT_ACCESS',
}
