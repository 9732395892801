import { Environment } from 'src/app/shared/types/environment.type';

export const environment: Environment = {
  code: 'many-digital',
  loginBackgroundImage: 'LoginBackground.jpg',
  loginLogoImage: 'app_icon.png',
  appIcon: 'app_icon.png',
  hostUrl: 'https://dev-api.manydigital.com/',
  production: true,
  clubName: 'MDDEV - Odense Boldklub config',
  teamId: 'dbl7ir1qugqz8nvp1vzn8im50',
  primaryColor: '#23c2a7',
  secondaryColor: '#ffc400',
  pushDefaultLanguage: 'da',
  supportedLanguages: ['en', 'no', 'de'],
  preferredLanguage: 'da',
  firebaseConfig: {
    apiKey: 'AIzaSyAIsCGnz287mbdT1RSybpYMLQOZDClJggE',
    authDomain: 'md-dev-7af2b.firebaseapp.com',
    databaseURL: 'https://md-dev-7af2b.firebaseio.com',
    projectId: 'md-dev-7af2b',
    storageBucket: 'md-dev-7af2b.appspot.com',
    messagingSenderId: '710499084967',
    appId: '1:710499084967:web:2e7a537ffa9334df354982',
    measurementId: 'G-T4RBVKHGC5',
  },
};
export const richTextConfig = {
  style: {
    fontFamily: 'Montserrat Light, sans-serif',
    fontWeight: 300,
    textAlign: 'center',
    font: '16px Montserrat Light',
  },
  font: {
    list: {
      'Montserrat Light': 'Montserrat Light',
      'Montserrat Bold': 'Montserrat Bold',
      'Montserrat ExtraBold': 'Montserrat ExtraBold',
    },
  },
};
