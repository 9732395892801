import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./feature/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'sponsor',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/sponsor/sponsor.module').then((m) => m.SponsorModule),
  },
  {
    path: 'library',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/library/library.module').then((m) => m.LibraryModule),
  },
  {
    path: 'audience-management',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/audience/audience.module').then(
        (m) => m.AudienceModule
      ),
  },
  {
    path: 'banner',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/banner-control/banner-control.module').then(
        (m) => m.BannerControlModule
      ),
  },
  {
    path: 'league-management',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/league-management/league-management.module').then(
        (m) => m.LeagueManagementModule
      ),
  },
  {
    path: 'league-overview',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/league-overview/league-overview.module').then(
        (m) => m.LeagueOverviewModule
      ),
  },
  {
    path: 'loyalty',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/loyalty/loyalty.module').then((m) => m.LoyaltyModule),
  },
  {
    path: 'email-segmentation',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/email-segmentation/email-segmentation.module').then(
        (m) => m.EmailSegmentationModule
      ),
  },
  {
    path: 'fan-activities',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/fan-activities/fan-activities.module').then(
        (m) => m.FanActivitiesModule
      ),
  },
  {
    path: 'user-management',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
  },
  {
    path: 'push-notification',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/push-notification/push-notification.module').then(
        (m) => m.PushNotificationModule
      ),
  },
  {

    path: 'spotmanagement',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/spot-management/spot-management.module').then(
        (m) => m.SpotManagementModule
      ),
  },
  {
    path: 'stream-management',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/stream-management/stream-management.module').then(
        (m) => m.StreamManagementModule
      ),
  },
  {
    path: 'message-central',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/message-inbox/message-inbox.module').then(
        (m) => m.MessageInboxModule
      ),
  },
  {
    path: 'settings',
    canActivate: [isAuthGuard],
    loadChildren: () =>
      import('./feature/settings-management/settings-management.module').then(
        (m) => m.SettingsManagementModule
      ),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
