import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { Observable, filter, map } from 'rxjs';
import {
  AppConfigModel,
  AuthModel,
} from '../../../../../src/app/shared/models/auth';
import {
  AppConfigResponse,
  AuthPayload,
  AuthResponse,
} from 'src/app/shared/interfaces/auth';
import { ForgotPasswordPayload } from 'src/app/shared/interfaces/auth/forgot-password-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthGateway {
  // TODO - consider extracting more consts and rename them to UPPER_CASE
  private readonly _url = `${environment.hostUrl}auth/V1/Authenticate`;
  private readonly _refreshUrl = `${environment.hostUrl}auth/V1/AuthenticateRefreshToken`;

  public constructor(
    private readonly _http: HttpClient,
    private readonly _firebase: AngularFireRemoteConfig
  ) {}

  /**
   * Validate login details provided
   *
   * @param payload {AuthPayload}
   * Takes the payload with the AuthPayload data structure
   *
   * @returns
   * Observable of type AuthModel
   *
   */
  public login(payload: AuthPayload): Observable<AuthModel> {
    return this._http
      .get<AuthResponse>(`${this._url}`, {
        params: { ...payload },
      })
      .pipe(map((res: AuthResponse) => new AuthModel(res)));
  }

  public forgotPassword(payload: ForgotPasswordPayload): Observable<unknown> {
    return this._http.post<unknown>(
      `${environment.hostUrl}auth/V1/ForgotPassword?email=${payload.email}&language=${payload.language}`,
      {}
    );
  }

  public refreshToken(refreshToken: string): Observable<AuthModel> {
    return this._http
      .get<AuthResponse>(`${this._refreshUrl}?refreshToken=${refreshToken}`)
      .pipe(map((res: AuthResponse) => new AuthModel(res)));
  }

  /**
   * Gets app permissions for a user from Firebase
   *
   * @returns
   * Observable of type AppConfigModel
   */
  public getAppConfig(): Observable<AppConfigModel> {
    return this._firebase.strings.pipe(
      map((res: unknown) => res as AppConfigResponse),
      filter((val: AppConfigResponse) => val.TeamManagerConfig !== undefined),
      map((config: AppConfigResponse) => new AppConfigModel(config))
    );
  }
}
