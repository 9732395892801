import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  readonly APP_ICON_URL = `assets/${environment.code}/images/${environment.appIcon}`;
  readonly LOGIN_LOGO_IMAGE = `assets/${environment.code}/images/${environment.loginLogoImage}`;
  readonly LOGO_BACKGROUND_IMAGE_URL = `assets/${environment.code}/images/${environment.loginBackgroundImage}`;

  constructor() {}

  /**
   * Gets url to image based on uuid
   * @params uuid
   * @returns url
   */
  getImageUrl(uuid: string | null) {
    return uuid
      ? `${environment.hostUrl}resource/V1/DownloadManyImage?uuid=${uuid}`
      : '';
  }
}
