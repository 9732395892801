export enum AppConfigEnum {
  MESSAGE_CENTRAL = 'messageCentral',
  SURVEY = 'survey',
  BANNER_STATS = 'bannerStats',
  BANNER_VOUCHER_AWARDABLE = 'bannerVoucherAwardable',
  QUIZ_POLL = 'quizPoll',
  LOYALTY = 'loyalty',
  STREAM_MANAGEMENT = 'streamManagement',
  LEAGUE_MANAGEMENT = 'leagueManagement',
  PLAYER_MANAGEMENT = 'playerManagement',
  AUDIENCE_MANAGEMENT = 'audienceManagement',
  SPOT_MANAGEMENT = 'spotManagement',
  LIBRARY_FOLDERS_AND_IMAGES = 'libraryFoldersAndImages',
  SETTINGS_SHOP = 'settingsShop',
  SETTINGS_TICKET = 'settingsTicket',
  USERS_STATISTICS = 'usersStatistics',
  ANALYTICS_STATISTICS = 'analyticsStatistics',
}
