import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { TokenService } from '../services/token/token.service';
import { AuthFacade } from 'src/app/data-access/auth/facade/auth.facade';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  private refreshing = false;
  constructor(
    private _tokenService: TokenService,
    private _authFacade: AuthFacade
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this._tokenService.getAccessToken();
    if (token) {
      const decodedToken = this._tokenService.decodeToken(token);
      const exp = decodedToken.exp ? parseInt(decodedToken.exp) * 1000 : null;
      const isExpired = exp ? new Date().getTime() >= exp : false;

      if (
        !request.url.includes('auth/V1/SignUp') &&
        !request.url.includes('auth/V1/AuthenticateRefreshToken')
      ) {
        if (isExpired) {
          this.refreshing = true;
          const refreshToken = this._tokenService.getRefreshToken() ?? '';

          return this._authFacade.refreshToken(refreshToken).pipe(
            switchMap((res) => {
              this._tokenService.setAccessToken(res.accessToken);
              this._tokenService.setRefreshToken(res.refreshToken);

              request = request.clone({
                headers: request.headers.set(
                  'Authorization',
                  `Bearer ${res.accessToken}`
                ),
              });

              this.refreshing = false;

              return next.handle(request);
            })
          );
        } else {
          request = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
        }
      }
    }
    return next.handle(request);
  }
}
