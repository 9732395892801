import {
  AppConfigEnum,
  RoleClaimEnum,
} from '../../../../../../src/app/shared/dicts/auth';
import { ModuleConfig } from 'src/app/shared/interfaces/auth';

export const MODULE_CONFIG_LIST: ModuleConfig[] = [
  {
    icon: 'home',
    label: 'SIDEBAR.HOME',
    path: '/dashboard',
    globalAdminRequired: false,
    claimsAccess: [],
  },
  {
    icon: 'data_usage',
    label: 'SIDEBAR.USER_STATISTICS',
    path: '/league-overview',
    globalAdminRequired: true,
    claimsAccess: [],
    configAccess: AppConfigEnum.USERS_STATISTICS,
  },
  {
    icon: 'calendar_view_day',
    label: 'SIDEBAR.BANNER_CONTROL',
    path: '/banner',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.SPONSOR_MANAGEMENT_ACCESS],
  },
  {
    icon: 'flag',
    label: 'SIDEBAR.FAN_ENGAGEMENT',
    path: '/fan-activities',
    globalAdminRequired: false,
    claimsAccess: [],
  },
  {
    icon: 'grade',
    label: 'SIDEBAR.LOYALTY',
    path: '/loyalty',
    globalAdminRequired: true,
    claimsAccess: [],
    configAccess: AppConfigEnum.LOYALTY,
  },
  {
    icon: 'people_outline',
    label: 'SIDEBAR.SPONSOR_MANAGEMENT',
    path: '/sponsor',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.SPONSOR_MANAGEMENT_ACCESS],
  },
  {
    icon: 'local_play',
    label: 'SIDEBAR.SPOT_MANAGEMENT',
    path: '/spotmanagement',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.SPOT_MANAGEMENT_ACCESS],
    configAccess: AppConfigEnum.SPOT_MANAGEMENT,
  },
  {
    icon: 'announcement',
    label: 'SIDEBAR.PUSH_MESSAGES',
    path: '/push-notification',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.PUSH_MANAGEMENT_ACCESS],
  },
  {
    icon: 'group',
    label: 'SIDEBAR.TARGETING_GROUP',
    path: '/email-segmentation',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.EMAIL_SEGMENTATION_ACCESS],
  },
  {
    icon: 'message',
    label: 'SIDEBAR.MESSAGE_CENTRAL',
    path: '/message-central',
    globalAdminRequired: false,
    claimsAccess: [],
    configAccess: AppConfigEnum.MESSAGE_CENTRAL,
  },
  {
    icon: 'photo_size_select',
    label: 'SIDEBAR.LIBRARY',
    path: '/library',
    globalAdminRequired: false,
    claimsAccess: [],
    configAccess: AppConfigEnum.LIBRARY_FOLDERS_AND_IMAGES,
  },
  {
    icon: 'group',
    label: 'SIDEBAR.LEAGUE_MANAGEMENT',
    path: '/league-management',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.LEAGUE_MANAGEMENT_ACCESS],
    configAccess: AppConfigEnum.LEAGUE_MANAGEMENT,
  },
  {
    icon: 'supervisor_account',
    label: 'SIDEBAR.AUDIENCE_MANAGEMENT',
    path: '/audience-management',
    globalAdminRequired: false,
    claimsAccess: [RoleClaimEnum.AUDIENCE_MANAGEMENT_ACCESS],
    configAccess: AppConfigEnum.AUDIENCE_MANAGEMENT,
  },
  {
    icon: 'videocam',
    label: 'SIDEBAR.STREAM_MANAGEMENT',
    path: '/stream-management',
    globalAdminRequired: true,
    claimsAccess: [],
    configAccess: AppConfigEnum.STREAM_MANAGEMENT,
  },
  {
    icon: 'people',
    label: 'SIDEBAR.USER_MANAGEMENT',
    path: '/user-management',
    globalAdminRequired: true,
    claimsAccess: [],
  },
  {
    icon: 'settings',
    label: 'SIDEBAR.SETTINGS',
    path: '/settings',
    globalAdminRequired: true,
    claimsAccess: [],
  },
];
