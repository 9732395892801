import { Injectable } from '@angular/core';
import { Menu } from '../../../../../src/app/shared/interfaces/ui/menu';
import { RoleClaimEnum } from '../../../../../src/app/shared/dicts/auth';
import { ModuleConfig } from '../../../../../src/app/shared/interfaces/auth';
import { AppConfigModel } from '../../../../../src/app/shared/models/auth';
import { MODULE_CONFIG_LIST } from './constants/module-config';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  /**
   * Gets menu definition using appConfig, roleClaims and GlobalAdmin permissions
   *
   * @param appConfig {AppConfigModel | undefined}
   * Takes param appConfig of type AppConfigModel
   *
   * @param roleClaims {RoleClaimEnum[]}
   * Takes param roleClaims of type RoleClaimEnum[]
   *
   * @param isGlobalAdmin {boolean}
   * Takes param isGlobalAdmin of type boolean
   *
   * @returns
   * Array of type Menu
   *
   */
  public getMenuDefinition(
    appConfig: AppConfigModel | undefined,
    roleClaims: RoleClaimEnum[],
    isGlobalAdmin: boolean
  ): Menu[] {
    if (appConfig) {
      const moduleConfig: ModuleConfig[] = this._getModuleConfig(
        MODULE_CONFIG_LIST,
        isGlobalAdmin
      );

      const moduleConfigFilteredByAppConfig: ModuleConfig[] =
        this._filterModuleConfigByAppConfig(moduleConfig, appConfig);

      const finalModuleConfig: ModuleConfig[] = this._filterModuleConfigByRoles(
        moduleConfigFilteredByAppConfig,
        roleClaims
      );

      return finalModuleConfig.map(({ label, path, icon }) => ({
        label,
        path,
        icon,
      }));
    }
    return [];
  }

  /**
   * Gets menu definition using moduleConfig and GlobalAdmin permissions
   *
   * @param moduleConfig {ModuleConfig[]}
   * Takes param moduleConfig of type ModuleConfig[]
   *
   * @param isGlobalAdmin {boolean}
   * Takes param isGlobalAdmin of type boolean
   *
   * @returns
   * Array of type ModuleConfig
   *
   */
  private _getModuleConfig(
    moduleConfig: ModuleConfig[],
    isGlobalAdmin: boolean
  ): ModuleConfig[] {
    if (!isGlobalAdmin) {
      return moduleConfig.filter(
        (config) => config.globalAdminRequired === false
      );
    }
    return MODULE_CONFIG_LIST;
  }

  /**
   * Gets menu definition using moduleConfig, appConfig permissions
   *
   * @param moduleConfig {ModuleConfig[]}
   * Takes param moduleConfig of type ModuleConfig[]
   *
   * @param appConfig {AppConfigModel}
   * Takes param isGlobalAdmin of type boolean
   *
   * @returns
   * Array of type ModuleConfig
   *
   */
  private _filterModuleConfigByAppConfig(
    moduleConfig: ModuleConfig[],
    appConfig: AppConfigModel
  ): ModuleConfig[] {
    return moduleConfig.filter((config) => {
      return (
        config.configAccess === undefined || appConfig[config.configAccess]
      );
    });
  }

  /**
   * Gets menu definition using moduleConfig, roleclaims permissions
   *
   * @param moduleConfig {ModuleConfig[]}
   * Takes param moduleConfig of type ModuleConfig[]
   *
   * @param roleClaims {RoleClaimEnum[]}
   * Takes param roleClaims of type array of RoleClaimEnum
   *
   * @returns
   * Array of type ModuleConfig
   *
   */
  private _filterModuleConfigByRoles(
    moduleConfig: ModuleConfig[],
    roleClaims: RoleClaimEnum[]
  ): ModuleConfig[] {
    return moduleConfig.filter((config) => {
      return this._checkRoles(config.claimsAccess, roleClaims);
    });
  }

  /**
   * checks roles from firebase
   *
   * @param moduleRoles {RoleClaimEnum[]}
   * Takes param moduleConfig of type ModuleConfig[]
   *
   * @param roleClaims {RoleClaimEnum[]}
   * Takes param roleClaims of type array of RoleClaimEnum
   *
   * @returns
   * boolean
   *
   */
  private _checkRoles(
    moduleRoles: RoleClaimEnum[],
    roleClaims: RoleClaimEnum[]
  ): boolean {
    for (let i = 0; i < moduleRoles.length; i++) {
      if (roleClaims.includes(moduleRoles[i])) {
        return true;
      }
    }
    return moduleRoles.length === 0 || false;
  }
}
