import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { Menu } from '../../interfaces/ui/menu';

@Component({
  selector: 'md-scaffold',
  standalone: true,
  imports: [CommonModule, TopbarComponent, SidebarComponent],
  templateUrl: './scaffold.component.html',
  styleUrls: ['./scaffold.component.scss'],
})
export class ScaffoldComponent {
  @Input() public orgName: string = '';
  @Input() public hideNav: boolean | null = false;
  @Input() public sideBarMenuItems: Menu[] = [];
  @Output() public logoutEvent = new EventEmitter<void>();

  public collapsed: boolean = false;
}
