import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthFacade } from '../../data-access/auth/facade/auth.facade';

// TODO - check errorMessage below, as it doesn't seem returned anywhere really, so... what's the point?
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  public constructor(private readonly _authFacade: AuthFacade) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let errorMessage: string | null;

    return next.handle(request).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          try {
            errorMessage = `An error occurred:${response.error.message}`;
          } catch {
            errorMessage = null;
          }
        }

        switch (response.status) {
          case 404:
            errorMessage = 'Not Found';
            break;
          case 401:
            this._authFacade.logout();
            errorMessage = 'Refresh';
            break;
          default:
            break;
        }

        return throwError(() => new Error('Something went wrong!'));
      })
    );
  }
}
