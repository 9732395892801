import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArrayToStringConverter {
  public arrayToString(dataArray: any[]): string {
    let convertedString: string = '';
    dataArray.map((e: any, index: number) => {
      if (index != dataArray.length - 1) {
        return (convertedString = convertedString + e.value + ',');
      } else {
        return (convertedString = convertedString + e.value);
      }
    });
    return convertedString;
  }
}
