import { createReducer, on } from '@ngrx/store';
import { RoleClaimEnum } from 'src/app/shared/dicts/auth';
import { StatusEnum } from '../../../../../src/app/shared/dicts/core';
import { AppConfigModel } from '../../../../../src/app/shared/models/auth';
import { authActions } from './auth.action';

export interface AuthState {
  status: StatusEnum;
  appConfig: AppConfigModel | undefined;
  authenticated: boolean;
  globalAdmin: boolean;
  roleClaims: RoleClaimEnum[];
}

export const initialAuthState: AuthState = {
  status: StatusEnum.PRISTINE,
  appConfig: undefined,
  authenticated: false,
  globalAdmin: false,
  roleClaims: [],
};

export const authReducer = createReducer<AuthState>(
  initialAuthState,
  on(
    authActions.initSuccess,
    (authState, { authenticated, roleClaims, globalAdmin }) => ({
      ...authState,
      status: authenticated ? StatusEnum.SUCCESS : StatusEnum.PRISTINE,
      authenticated,
      roleClaims,
      globalAdmin,
    })
  ),
  on(authActions.login, (authState) => ({
    ...authState,
    status: StatusEnum.LOADING,
  })),
  on(authActions.loginSuccess, (authState, { roleClaims, globalAdmin }) => ({
    ...authState,
    status: StatusEnum.SUCCESS,
    authenticated: true,
    globalAdmin,
    roleClaims,
  })),
  on(authActions.loginError, (authState) => ({
    ...authState,
    status: StatusEnum.ERROR,
    authenticated: false,
  })),
  on(authActions.refreshToken, (authState) => ({
    ...authState,
    status: StatusEnum.LOADING,
  })),
  on(
    authActions.refreshTokenSuccess,
    (authState, { roleClaims, globalAdmin }) => ({
      ...authState,
      status: StatusEnum.SUCCESS,
      authenticated: true,
      globalAdmin,
      roleClaims,
    })
  ),
  on(authActions.loadAppConfigSuccess, (authState, { appConfig }) => ({
    ...authState,
    appConfig,
  })),
  on(authActions.logoutSuccess, (authState) => ({
    ...initialAuthState,
    appConfig: authState.appConfig,
  }))
);
