import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ScaffoldComponent } from './shared/ui/scaffold/scaffold.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DataAccessModule } from './data-access/data-access.module';
import { AppRoutingModule } from './app-routing.module';
import { NgChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    DataAccessModule,
    ScaffoldComponent,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    NgChartsModule,
  ],
  providers: [DatePipe],
})
export class AppModule {}
