<md-topbar *ngIf="!hideNav; else fullScreen" (toggleEvent)="collapsed = !collapsed" (logoutEvent)="logoutEvent.emit()"
  [orgName]="orgName">
  <md-sidebar [collapsed]="collapsed" [menuItems]="sideBarMenuItems"></md-sidebar>
  <div class="main" [ngClass]="{ 'main--expanded': collapsed }">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</md-topbar>

<ng-template #fullScreen>
  <div>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>